import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEventListener } from '../../hooks'
import get, { getAtomicAsset } from '../helpers/Api'
import { getAttributeNames, getDefaultAttrNames } from '../helpers/FormatLinks'
import { MarketInfoDetails2 } from '../marketinfo/MarketInfoDetails2'
import { MarketAssetPreviewImage } from './MarketAssetPreviewImage'
import MarketPreviewDetailsTable from './MarketPreviewDetailsTable'

export const AssetPreviewInfo2 = ({
    cancel,
    asset,
    newOwner,
    currentAsset,
    bundleView,
    setPriceInfo,
    priceInfo,
    buttons,
    show,
}) => {
    const [attrNames, setAttrNames] = useState(getDefaultAttrNames())
    const [atomicData, setAtomicData] = useState(null)
    const [selectedAsset, setSelectedAsset] = useState(0)

    const { t } = useTranslation('common')

    let {
        assetId,
        templateId,
        assets,
        author,
        schema,
        bundle,
        standard,
        symbol,
        buyer,
    } = asset

    const getPriceInfo = async () => {
        if (!priceInfo && (symbol || assetId || templateId)) {
            const pInfo = await get(
                symbol
                    ? 'price-info-fts/' + symbol
                    : assetId
                    ? 'price-info/' + assetId
                    : 'price-info-template/' + templateId,
            )

            setPriceInfo(pInfo)
        }
    }

    const getAtomicData = () => {
        const controller = new AbortController()
        if (standard === 'atomicassets')
            getAtomicAsset(assetId)
                .then((res) => {
                    res && res.success && setAtomicData(res.data)
                })
                .catch((e) => {
                    console.error(e)
                })
        return () => controller.abort()
    }

    const keyHandler = ({ key }) => {
        const ESCAPE_KEYS = ['27', 'Escape']
        if (ESCAPE_KEYS.includes(String(key))) {
            cancel()
        }
    }

    useEventListener('keydown', keyHandler)

    useEffect(() => {
        if (!priceInfo || !atomicData) {
            getAtomicData()
            getPriceInfo()
            const controller = new AbortController()
            getAttributeNames(author, schema, controller.signal).then(
                (result) => {
                    if (result && !('errors' in result)) setAttrNames(result)
                },
            )
        }
    }, [assetId])

    return (
        <div className="grid w-full grid-cols-3 bg-zinc-300 dark:!bg-paperd">
            <div className="w-full h-full col-span-3 md:col-span-1 md:relative md:flex md:justify-center md:item-center dark:bg-zinc-950 bg-zinc-200 md:h-full">
                <MarketAssetPreviewImage
                    asset={assets ? assets[0] : asset}
                    currentAsset={currentAsset}
                />
            </div>

            <div className="col-span-3 pl-4 md:col-span-2 dark:bg-zinc-900 bg-zinc-100">
                <div className="w-full mt-5 text-left">
                    <MarketPreviewDetailsTable
                        asset={asset}
                        newOwner={newOwner}
                        bundleView={bundleView}
                        currentAsset={currentAsset}
                        attrNames={attrNames}
                        atomicData={atomicData}
                        showData={false}
                        className="none"
                    />
                    <MarketInfoDetails2
                        asset={asset}
                        priceInfo={priceInfo}
                        currentAsset={currentAsset}
                        assets={assets}
                        bundle={bundle}
                        cancel={cancel}
                        className="none"
                    />
                    {!buyer ? (
                        <div className="h-32 mx-5 mb-4">{buttons}</div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    )
}
