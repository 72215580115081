import cn from 'classnames'
import React, { useEffect, useRef } from 'react'
import { timeSince } from '../../utils/date'

export const TimeSinceListed = ({ asset, position = 'justify-center' }) => {
    const { listingTime } = asset
    const element = useRef(null)

    useEffect(() => {
        if (!listingTime) return
        const intervalId = setInterval(() => {
            if (element.current)
                element.current.innerText = timeSince(listingTime, Date.now())
        }, 1000)
        return () => clearInterval(intervalId)
    })
    return (
        <div
            className={cn(
                'absolute bg-paget top-0 w-full flex flex-wrap text-sm my-auto text-white',
                position,
            )}
        >
            <div className={'ml-2'} ref={element}>
                {timeSince(listingTime, Date.now())}
            </div>
            <div className={'ml-2'}>ago</div>
        </div>
    )
}
