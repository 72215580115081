import { Checkbox, Tooltip } from '@mui/material'
import {
    Modal,
    ModalBody,
    ModalContent,
    useDisclosure,
} from '@nextui-org/react'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazy-load'
import config from '../../config.json'

import AssetPreviewDisplay from '../assetpreviewdisplay/AssetPreviewDisplay'
import BackedInfo from '../backedinfo/BackedInfo'
import { MarketCard } from '../card/MarketCard'
import Link2 from '../common/util/input/Link2'
import MarketButtons from '../marketbuttons/MarketButtons'
import { MintInfo } from '../mintinfo/MintInfo'
import RankInfo from '../rankinfo/RankInfo'
import { useSharedState } from '../waxplorer/Waxplorer'
import { AssetDetailsPopupContent } from './AssetDetailsPopupContent'
import { AuctionTimeleft } from './AuctionTimeleft'
import { CollectionAssetName } from './CollectionAssetName'
import { AssetPreviewInfo2 } from './MarketAssetPreviewInfo'
import MoreOptions from './MoreOptions'
import { TimeSinceListed } from './TimeSinceListed'
import { useTheme } from 'next-themes'
import { useElementSize } from '../../hooks'

// @ts-ignore
const MarketAssetPreview = ({
    asset,
    selectedAsset,
    onAdd,
    bundleView,
    page,
    showOwner,
    selectable,
    searchOwner,
    clickAsset,
    index,
    assetType,
    previewDuration = 2,
    handleCheck,
    selectedAssets,
    transferredAssets,
    errorAssets,
}) => {
    const [state, dispatch] = useSharedState()
    const { theme } = useTheme()
    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null
    const [priceInfo, setPriceInfo] = useState(null)
    const [currentAsset, setCurrentAsset] = useState(0)
    const [newOwner, setNewOwner] = useState(null)
    const [showMenu, setShowMenu] = useState(false)
    const [created, setCreated] = useState(false)
    const [error, setError] = useState(null)
    const [staked, setStaked] = useState(false)
    const [bought, setBought] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [edited, setEdited] = useState(false)
    const [sold, setSold] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [transferred, setTransferred] = useState(false)

    const { assets, bundle, orderName, offer, usd_offer, isAuction, saleId } =
        asset

    let {
        assetId,
        aAssetId,
        author,
        name,
        image,
        previmg,
        backimg,
        mint,
        rank,
        rarityScore,
        myMint,
        backedTokens,
        standard,
        claimer,
        orderId,
        verified,
        blacklisted,
        symbol,
        market,
        mdata,
        collectionImage,
        collectionThumbnail,
        thumbnail,
        universalPreview,
        templateId,
    } = asset

    if (standard && standard === 'atomicassets')
        assetId = aAssetId ? aAssetId : assetId

    const assetIds = []

    const handleBought = (buyInfo) => {
        if (buyInfo) {
            const { bought, error } = buyInfo

            if (bought) {
                setNewOwner(userName)
                asset['owner'] = newOwner
                asset['offer'] = null
            }

            setError(error)
            dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
            setBought(bought)
        }
        setIsLoading(false)
    }

    const handleSell = (sellInfo) => {
        if (sellInfo) {
            const wasSold = sellInfo['sold']
            const market = sellInfo['market']
            const offer = sellInfo['offer']

            if (wasSold) {
                if (wasSold && typeof wasSold === 'object') {
                    const transferredItems = {}
                    Object.keys(wasSold).map((assetId) => {
                        const asset = wasSold[assetId]
                        const localId =
                            asset['standard'] === 'atomicassets'
                                ? asset['aAssetId']
                                : asset['assetId']
                        transferredItems[localId] = asset
                        transferredItems[localId]['newOwner'] = asset['market']
                        transferredItems[localId]['owner'] = asset['market']
                        transferredItems[localId]['market'] = asset['market']
                        transferredItems[localId]['offer'] = offer
                        transferredItems[localId]['seller'] = userName
                        transferredItems[localId]['sold'] = true
                    })

                    dispatch({
                        type: 'SET_TRANSFERRED_ASSETS',
                        payload: transferredItems,
                    })
                }
                setNewOwner(market)
                asset['owner'] = market
                asset['market'] = market
                asset['offer'] = offer
                asset['seller'] = userName
                setCanceled(false)
            }

            if (sellInfo['error']) {
                setError(sellInfo['error'])
            }

            setSold(wasSold)
        }
        setIsLoading(false)
    }

    const handleEditOffer = (offerInfo) => {
        if (offerInfo) {
            const edited = offerInfo['edited']
            const wasCanceled = offerInfo['canceled']

            if (offerInfo['error']) {
                setError(offerInfo['error'])
                setIsLoading(false)
                return
            }

            if (edited) {
                setEdited(true)
                asset['offer'] = edited
            }

            if (wasCanceled) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setCanceled(wasCanceled)
            }
        }
        setIsLoading(false)
    }

    const handleCancel = (cancel) => {
        try {
            if (cancel) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setCanceled(cancel)
            }

            setIsLoading(false)
        } catch (e) {
            console.error(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleStake = (stake) => {
        try {
            if (stake) {
                setNewOwner('s.rplanet')
                asset['owner'] = userName
                asset['offer'] = null
                setStaked(true)
                setUnstaked(false)
                setBought(false)
            }

            setIsLoading(false)
        } catch (e) {
            console.error(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleTransfer = (sellInfo) => {
        if (sellInfo) {
            const wasTransferred = sellInfo['transferred']
            const owner = sellInfo['receiver']

            if (wasTransferred) {
                setNewOwner(owner)
                asset['owner'] = owner
                asset['sender'] = userName
            }

            setTransferred(wasTransferred)
        }
        setIsLoading(false)
    }

    if (assets && !bundleView) {
        const a = assets[currentAsset]
        assetId = a.assetId
        author = a.author
        name =
            assets.length > 1
                ? orderName
                    ? `${currentAsset + 1}/${assets.length} ${orderName.substr(
                          0,
                          60,
                      )}`
                    : `${assets[currentAsset].name} (Bundle ${
                          currentAsset + 1
                      }/${assets.length})`
                : a.name
        image = a.image
        thumbnail = a.thumbnail
        universalPreview = a.universalPreview
        backimg = a.backimg
        mint = a.mint
        mdata = a.mdata
        rarityScore = a.rarityScore
        rank = a.rank
        backedTokens = a.backedTokens
        assets.forEach((asset) => assetIds.push(asset.assetId))
    } else {
        assetIds.push(assetId)
    }

    useEffect(() => {
        if (errorAssets && Object.keys(errorAssets).length > 0) {
            for (let localId of Object.keys(errorAssets)) {
                if (
                    assetId.toString() === localId.toString() ||
                    (aAssetId && aAssetId.toString() === localId.toString())
                ) {
                    setError(errorAssets[localId])
                }
            }
        }
        if (Object.keys(transferredAssets).length > 0) {
            for (let localId of Object.keys(transferredAssets)) {
                if (
                    assetId.toString() === localId.toString() ||
                    (aAssetId && aAssetId.toString() === localId.toString())
                ) {
                    setNewOwner(transferredAssets[localId]['newOwner'])
                    asset['owner'] = transferredAssets[localId]['newOwner']
                    asset['newOwner'] = transferredAssets[localId]['newOwner']
                    asset['sender'] = userName

                    if (transferredAssets[localId]['sold']) {
                        setSold(true)
                        setNewOwner(transferredAssets[localId]['owner'])
                        asset['offer'] = transferredAssets[localId]['offer']
                        asset['market'] = transferredAssets[localId]['market']
                    } else if (transferredAssets[localId]['bought']) {
                        setBought(true)
                        asset['offer'] = null
                    } else if (transferredAssets[localId]['transferred']) {
                        setTransferred(true)
                    } else if (transferredAssets[localId]['transferred']) {
                        setCanceled(true)
                    }
                }
            }
        }
    }, [
        selectedAssets && selectedAssets.length,
        errorAssets && Object.keys(errorAssets).length,
        transferredAssets && Object.keys(transferredAssets).length,
    ])

    useEffect(() => {
        if (page === 'live') {
            setIsLoading(false)
            setBought(false)
        }
    }, [page, assetId])

    const prevAsset = async () => {
        if (currentAsset > 0) setCurrentAsset(currentAsset - 1)
        else setCurrentAsset(assets.length - 1)
    }

    const nextAsset = async () => {
        if (currentAsset === assets.length - 1) setCurrentAsset(0)
        else setCurrentAsset(currentAsset + 1)
    }

    const assetData = mdata ? JSON.parse(mdata) : null

    let video = ''

    if (
        assetData &&
        Object.keys(assetData).includes('video') &&
        assetData['video']
    ) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `${config.ipfs}${assetData['video']}`
    } else if (image && image.includes('video:')) {
        video = image.replace('video:', '')
        video = video.includes('http') ? video : `${config.ipfs}${video}`
    }

    if (thumbnail && video) {
        const hashes = thumbnail.split('hash=')
        if (hashes.length > 1) {
            const hash = hashes[1]
            if (video.includes(hash)) {
                video = thumbnail
            } else {
                video = null
            }
        }
    }

    const toggleShowMenu = () => {
        setShowMenu(!showMenu)
    }

    const handleCreateOffer = (offerInfo) => {
        if (offerInfo) {
            const wasCreated = offerInfo['created']

            if (offerInfo['error']) {
                setError(offerInfo['error'])
                setIsLoading(false)
                return
            }

            setCreated(wasCreated)
        }
        setIsLoading(false)
    }

    const assetIncluded = () => {
        return selectedAssets
            .map(
                (a) =>
                    Object.assign(
                        {},
                        a,
                        a.assets?.length > 0 ? a.assets[0] : null,
                    ).assetId,
            )
            .includes(
                (asset.assets?.length > 0 ? asset.assets[0] : asset).assetId,
            )
    }

    const checked = assetIncluded()

    /* check box and share  */
    const cardHeader = (
        <div className={'absolute w-full h-8 z-10'}>
            <div className={'absolute flex m-auto h-10 w-8'}>
                {selectable && selectable(asset) && (
                    <Checkbox
                        checked={checked}
                        onChange={(e) => handleCheck(e, asset)}
                        className=" text-primary"
                    />
                )}
            </div>
            <div />
            <div />
            {page !== 'newbuyoffer' && page !== 'editor' && (
                <MoreOptions
                    setShowMenu={setShowMenu}
                    newOwner={newOwner}
                    showMenu={showMenu}
                    asset={asset}
                    handleSell={handleSell}
                    handleTransfer={handleTransfer}
                    handleStake={handleStake}
                    handleCreateOffer={handleCreateOffer}
                    created={created}
                    setCreated={setCreated}
                    staked={staked}
                    setError={setError}
                    sold={sold}
                    setSold={setSold}
                    setIsLoading={setIsLoading}
                    transferred={transferred}
                    toggleShowMenu={toggleShowMenu}
                    currentAsset={currentAsset}
                />
            )}
        </div>
    )

    const href = symbol
        ? '/pack/' +
          symbol +
          (orderId ? `?listing_id=${orderId ? orderId : ''}` : '') +
          (assetId ? `${orderId ? '&' : '?'}asset_id=${assetId}` : '') +
          (market ? `${orderId || assetId ? '&' : '?'}market=${market}` : '')
        : market === 'waxbuyoffers'
        ? `/offer/${orderId}`
        : saleId
        ? `/sale/${saleId}`
        : offer
        ? isAuction
            ? '/auction/' + market + '/' + orderId
            : '/listing/' + market + '/' + (orderId ? orderId : assetId)
        : assetId
        ? '/asset/' + assetId
        : templateId
        ? '/template/' + templateId
        : ''

    const { isOpen, onOpen, onOpenChange } = useDisclosure()

    const [elementSize, elementRef] = useElementSize()
    let cardWidth = elementSize.width - 60

    return (
        <LazyLoad offset={200}>
            <div>
                <MarketCard
                    height={isAuction ? 'high' : 'normal'}
                    cardHeader={cardHeader}
                    page={page}
                    new={asset['new']}
                >
                    <div
                        className={
                            ' relative flex  justify-center items-center  !aspect-1 '
                        }
                    >
                        <div
                            className={cn(
                                'flex justify-center items-center w-full h-full',
                            )}
                        >
                            <Link2
                                inactive={
                                    page === 'newbuyoffer' ||
                                    page === 'editor' ||
                                    clickAsset
                                }
                                href={href}
                                onClick={(e) => {
                                    const elem = window.document.getElementById(
                                        'AssetDetailsPopupContent' +
                                            (assetId ? assetId : templateId),
                                    )
                                    if (!elem || !elem.contains(e.target)) {
                                        dispatch({
                                            type: 'SET_ACTION_SEARCH',
                                            payload: {
                                                actionSearch: '',
                                                asset: null,
                                                searchType: '',
                                                searchID: '',
                                                includeDrops: false,
                                            },
                                        })
                                    }
                                }}
                                altOnClick={
                                    clickAsset
                                        ? () => clickAsset(index)
                                        : () => {}
                                }
                                className={cn('h-full w-full ')}
                            >
                                <div
                                    className={cn(
                                        'text-center cursor-pointer h-full w-full',
                                    )}
                                >
                                    <Tooltip
                                        leaveDelay={100}
                                        title={
                                            <AssetDetailsPopupContent
                                                asset={
                                                    asset.assets
                                                        ? asset.assets[
                                                              currentAsset
                                                          ]
                                                        : asset
                                                }
                                                offer={offer}
                                                usd_offer={usd_offer}
                                                userName={userName}
                                                priceInfo={priceInfo}
                                                setPriceInfo={setPriceInfo}
                                            />
                                        }
                                        placement="right"
                                        arrow
                                        enterDelay={700}
                                        enterNextDelay={700}
                                        disableTouchListener
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    borderRadius: '1rem',
                                                    backgroundColor:
                                                        theme === 'light'
                                                            ? 'white'
                                                            : '#141414FA',
                                                    maxWidth: 'none',
                                                },
                                            },
                                        }}
                                    >
                                        <div className="w-full h-full overflow-hidden">
                                            <AssetPreviewDisplay
                                                name={`${name} by ${author}`}
                                                image={image}
                                                prevImg={
                                                    universalPreview
                                                        ? universalPreview
                                                        : thumbnail
                                                        ? thumbnail
                                                        : previmg
                                                }
                                                video={video}
                                                backImg={backimg}
                                                previewDuration={
                                                    previewDuration
                                                }
                                                className={cn('h-full')}
                                                imgClassName={cn(
                                                    'object-contain transition transform h-full w-full',
                                                    'duration-500 md:hover:scale-100 hover:scale-105  cursor-pointer',
                                                )}
                                                mdata={mdata}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            </Link2>

                            {bundle && !bundleView && assets.length > 1 ? (
                                <button
                                    className={cn(
                                        'absolute m-auto h-full border-0 text-primary cursor-pointer',
                                        'outline-none text-2xl left-0 opacity-80 hover:opacity-100 w-5 h-5',
                                    )}
                                    onClick={prevAsset}
                                >
                                    ‹
                                </button>
                            ) : null}
                            {bundle && !bundleView && assets.length > 1 && (
                                <button
                                    className={cn(
                                        'absolute m-auto h-full border-0 text-primary cursor-pointer',
                                        'outline-none text-2xl right-0 opacity-80 hover:opacity-100 w-5 h-5',
                                    )}
                                    onClick={nextAsset}
                                >
                                    ›
                                </button>
                            )}
                        </div>
                        {mint && !rank && !rarityScore ? (
                            <MintInfo
                                top={'-bottom-4 right-1'}
                                mint={mint}
                                myMint={myMint}
                            />
                        ) : (
                            ''
                        )}
                        {rank && rarityScore ? (
                            <RankInfo rank={rank} rarityScore={rarityScore} />
                        ) : (
                            ''
                        )}
                        {backedTokens && (
                            <BackedInfo backedTokens={backedTokens} />
                        )}
                    </div>
                    <div
                        className={'p-4 flex flex-wrap h-full justify-between'}
                    >
                        <CollectionAssetName
                            linkInactive={
                                page === 'newbuyoffer' ||
                                page === 'editor' ||
                                clickAsset
                            }
                            collectionImage={
                                collectionThumbnail
                                    ? collectionThumbnail
                                    : collectionImage
                            }
                            onClick={() => {
                                dispatch({
                                    type: 'SET_ACTION_SEARCH',
                                    payload: {
                                        actionSearch: '',
                                        asset: null,
                                        searchType: '',
                                        searchID: '',
                                        includeDrops: false,
                                    },
                                })
                            }}
                            altOnClick={
                                clickAsset ? () => clickAsset(index) : () => {}
                            }
                            href={href}
                            name={name ? name : assetId}
                            collection={author}
                            verified={verified}
                            blacklisted={blacklisted}
                            cardWidth={cardWidth}
                            elementRef={elementRef}
                        />

                        {/* bottom price floor detail btn login btn */}
                        {!selectedAsset && selectedAsset !== 0 && (
                            <MarketButtons
                                view={''}
                                onOpen={onOpen}
                                className={'mt-auto h-auto w-full'}
                                onAdd={onAdd}
                                asset={asset}
                                newOwner={newOwner}
                                searchOwner={searchOwner}
                                claimer={claimer}
                                handleSell={handleSell}
                                handleBought={handleBought}
                                handleCancel={handleCancel}
                                handleEditOffer={handleEditOffer}
                                assetType={assetType}
                                page={page}
                                bought={bought}
                                canceled={canceled}
                                edited={edited}
                                error={error}
                                setError={setError}
                                sold={sold}
                                setSold={setSold}
                                setIsLoading={setIsLoading}
                                isLoading={isLoading}
                                showOwner={showOwner}
                                currentAsset={currentAsset}
                            />
                        )}

                        {isAuction && <AuctionTimeleft asset={asset} />}
                        {page === 'live' && <TimeSinceListed asset={asset} />}
                    </div>
                </MarketCard>

                <Modal
                    size={'5xl'}
                    isOpen={isOpen}
                    onOpenChange={onOpenChange}
                    scrollBehavior={'inside'}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    classNames={{
                        body: 'p-0',
                        closeButton:
                            'absolute top-2 right-2 z-50 bg-red-500 text-white hover:bg-red-600',
                    }}
                >
                    <ModalContent>
                        <ModalBody className="w-full px-0 py-0 ">
                            <AssetPreviewInfo2
                                asset={assets ? assets[currentAsset] : asset}
                                newOwner={newOwner}
                                priceInfo={priceInfo}
                                setPriceInfo={setPriceInfo}
                                currentAsset={currentAsset}
                                bundleView={bundleView}
                                show={onOpen}
                                buttons={
                                    <MarketButtons
                                        view={'full'}
                                        className={
                                            '-mt-4 h-full w-full md1:w-[380px] '
                                        }
                                        onAdd={onAdd}
                                        asset={asset}
                                        newOwner={newOwner}
                                        searchOwner={searchOwner}
                                        claimer={claimer}
                                        handleSell={handleSell}
                                        handleBought={handleBought}
                                        handleCancel={handleCancel}
                                        handleEditOffer={handleEditOffer}
                                        assetType={assetType}
                                        page={page}
                                        bought={bought}
                                        canceled={canceled}
                                        edited={edited}
                                        error={error}
                                        setError={setError}
                                        sold={sold}
                                        setSold={setSold}
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                        showOwner={showOwner}
                                        currentAsset={currentAsset}
                                    />
                                }
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </LazyLoad>
    )
}

function assetPropsAreEqual(prevAsset, nextAsset) {
    let item1 = 0
    let item2 = 0

    const id1 = (
        prevAsset.asset.assets?.length > 0
            ? prevAsset.asset.assets[0]
            : prevAsset.asset
    ).assetId

    if (
        prevAsset.selectedAssets &&
        prevAsset.selectedAssets
            .map((a) => (a.assets?.length > 0 ? a.assets[0] : a).assetId)
            .includes(id1)
    ) {
        item1 = id1
    }

    const id2 = (
        nextAsset.asset.assets?.length > 0
            ? nextAsset.asset.assets[0]
            : nextAsset.asset
    ).assetId

    if (
        nextAsset.selectedAssets &&
        nextAsset.selectedAssets
            .map((a) => (a.assets?.length > 0 ? a.assets[0] : a).assetId)
            .includes(id2)
    ) {
        item2 = id2
    }

    return (
        ((!prevAsset.selectedAssets && !nextAsset.selectedAssets) ||
            (prevAsset.selectedAssets &&
                nextAsset.selectedAssets &&
                prevAsset.selectedAssets.length ===
                    nextAsset.selectedAssets.length)) &&
        ((!prevAsset.transferredAssets && !nextAsset.transferredAssets) ||
            (prevAsset.transferredAssets &&
                nextAsset.transferredAssets &&
                prevAsset.transferredAssets.length ===
                    nextAsset.transferredAssets.length)) &&
        ((!prevAsset.errorAssets && !nextAsset.errorAssets) ||
            (prevAsset.errorAssets &&
                nextAsset.errorAssets &&
                Object.keys(prevAsset.errorAssets).length === 0 &&
                Object.keys(nextAsset.errorAssets).length === 0)) &&
        prevAsset.page !== 'live' &&
        nextAsset.page !== 'live' &&
        !prevAsset.enableLiveAutoBuy &&
        !nextAsset.enableLiveAutoBuy &&
        id1 === id2 &&
        prevAsset.index === nextAsset.index &&
        item1 === item2 &&
        prevAsset.isSelected === nextAsset.isSelected &&
        item1 === 0 &&
        item2 === 0
    )
}

export default React.memo(MarketAssetPreview, assetPropsAreEqual)
