import cn from 'classnames'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIndicator from '../checkindicator/CheckIndicator'
import ErrorNote from '../common/util/ErrorNote'
import MainButton from '../common/util/input/MainButton'
import MainButton2 from '../common/util/input/MainButton2'
import get from '../helpers/Api'
import {
    formatUSD,
    formatWAX,
    formatWAXS,
    getUsdWax,
    openSearchPopup,
} from '../helpers/FormatLinks'
import {
    getBoostAction,
    performLogin,
    signAlcorNFTBuy,
    signAtomicMarketBuy,
    signNFTHiveBuy,
    signSimpleMarketBuy,
    signWaxInventoryBuy,
    signWaxplorerBuy,
} from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { Context, useSharedState } from '../waxplorer/Waxplorer'

export const MarketButtons = ({
    asset,
    loadBack,
    bought,
    canceled,
    className = 'w-full h-8 mt-auto',
    edited,
    error,
    assetType,
    handleBought,
    handleCancel,
    handleSell,
    handleEditOffer,
    isLoading,
    newOwner,
    onAdd,
    page,
    setError,
    setIsLoading,
    setSold,
    sold,
    showOwner,
    currentAsset,
    view,
    onOpen,
}) => {
    const [state, dispatch, { login }] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null
    const { t } = useTranslation('common')

    const {
        assets,
        bundle,
        offer,
        usd_offer,
        orderId,
        currency,
        forSale,
        price,
        usd_price,
        market,
        buyer,
        seller,
    } = asset

    const { templateId, blacklisted, author, rarity, schema } = assets
        ? assets[currentAsset]
        : asset

    const { lowest, attributesFloor } = assets ? assets[currentAsset] : asset

    let { owner, assetId, aAssetId, standard, contract } = assets
        ? assets[currentAsset]
        : asset

    const assetIds = []

    if (bundle) {
        assets.forEach((asset) => assetIds.push(asset.assetId))
    } else {
        assetIds.push(assetId)
    }

    const directBuy = async () => {
        const quantity = parseFloat(offer ? offer : price)
        setIsLoading(true)

        const usd_wax = await getUsdWax()

        try {
            let result
            switch (market) {
                case 'atomicmarket':
                    {
                        result = await signAtomicMarketBuy(
                            activeUser,
                            orderId,
                            quantity,
                            currency,
                            usd_wax['usd_wax'],
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'waxplorercom':
                    {
                        result = await signWaxplorerBuy(
                            activeUser,
                            quantity,
                            orderId,
                            asset['assetId'],
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'simplemarket':
                    {
                        result = await signSimpleMarketBuy(
                            activeUser,
                            quantity,
                            assetId,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'nft.hive':
                    {
                        result = await signNFTHiveBuy(
                            activeUser,
                            quantity,
                            orderId,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'waxinventory':
                    {
                        result = await signWaxInventoryBuy(
                            activeUser,
                            quantity,
                            assetId,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
                case 'alcornftswap':
                    {
                        result = await signAlcorNFTBuy(
                            activeUser,
                            orderId,
                            quantity,
                        )

                        handleBought({
                            bought: true,
                            error: null,
                            asset: asset,
                        })
                    }
                    break
            }
        } catch (e) {
            handleBought({
                bought: false,
                error: e.message,
                asset: asset,
            })
            setError(e.message)
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const autoBuyEnabled =
        state.enableLiveAutoBuy && state.enableLiveAutoBuy.price

    useEffect(() => {
        const asyncFunc = async () => {
            if (
                state.enableLiveAutoBuy &&
                (!state.enableLiveAutoBuy.collection ||
                    state.enableLiveAutoBuy.collection === author) &&
                (!state.enableLiveAutoBuy.schema ||
                    state.enableLiveAutoBuy.schema === schema) &&
                (!state.enableLiveAutoBuy.rarity ||
                    state.enableLiveAutoBuy.rarity === rarity) &&
                (!state.enableLiveAutoBuy.templateId ||
                    state.enableLiveAutoBuy.templateId.toString() ===
                        templateId.toString()) &&
                page === 'live' &&
                offer <= state.enableLiveAutoBuy.price &&
                asset['new'] &&
                buyable
            ) {
                dispatch({
                    type: 'SET_ENABLE_LIVE_AUTOBUY',
                    payload: null,
                })
                state.skipBuyConfirmation ? directBuy() : buy()
            }
        }
        if (autoBuyEnabled) asyncFunc()
    }, [autoBuyEnabled])

    const popError = null

    const getOrderId = async (assetId, market, bundle) => {
        const orderId = await get(
            'get-order-id/' +
                (market ? market + '/' : '') +
                assetId +
                '?bundle=' +
                bundle,
        )
        return orderId ? orderId['orderId'] : null
    }

    const getPackOrderId = async (symbol, market, seller, offer) => {
        const orderId = await get(
            'get-pack-order-id/' +
                market +
                '/' +
                seller +
                '/' +
                symbol +
                '?offer=' +
                offer,
        )
        return orderId ? orderId['orderId'] : null
    }

    const cancel = async () => {
        let { assets, orderId } = asset
        let { assetId, author, symbol, owner } = assets ? assets[0] : asset

        let { market } = asset

        if (!market) {
            market = owner
        }

        setError(null)
        setIsLoading(true)

        let newOrderId = null

        if (assetId || (assetIds.length > 0 && assetIds[0]))
            newOrderId = await getOrderId(
                assetId ? assetId : assetIds[0],
                market ? market : owner,
                assets && assets.length > 1,
            )

        if (symbol)
            newOrderId = await getPackOrderId(
                symbol,
                market ? market : owner,
                userName,
                offer,
            )

        if (newOrderId) orderId = newOrderId

        try {
            let result
            switch (market) {
                case 'atomicmarket':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        sale_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxbuyoffers':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'eraseoffer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        offer_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'nft.hive':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'nft.hive',
                                    name: 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        sale_id: orderId,
                                    },
                                },
                                {
                                    account: 'nft.hive',
                                    name: 'withdraw',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        user: userName,
                                        quantity:
                                            (author === 'a.rplanet'
                                                ? (1.0).toFixed(4)
                                                : 1) +
                                            ' ' +
                                            symbol,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxinventory':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancel',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        owner: userName,
                                        assetids: [assetId],
                                    },
                                },
                                {
                                    account: 'simpleassets',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        claimer: userName,
                                        assetids: [assetId],
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'simplemarket':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancel',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        owner: userName,
                                        assetids: [assetId],
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxplorercom':
                    result = await activeUser.session.transact(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        sale_id: orderId,
                                    },
                                },
                                {
                                    account: 'simpleassets',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser.requestPermission
                                                    ? activeUser.requestPermission
                                                    : 'active',
                                        },
                                    ],
                                    data: {
                                        claimer: userName,
                                        assetids: assetIds,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
            }
        } catch (e) {
            console.error(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const buy = () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (bought) => handleBought(bought),
        })
        dispatch({ type: 'SET_ACTION', payload: 'buy' })
    }

    const sell = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleSell(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'sell' })
    }

    const edit = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (offerInfo) => handleEditOffer(offerInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'editoffer' })
    }

    const disMissError = () => {
        if (popError) dispatch({ type: 'SET_ERROR', payload: null })
        const errorAssets = state.errorAssets
        if (errorAssets) {
            if (assetId) delete errorAssets[assetId.toString()]
            if (aAssetId) delete errorAssets[aAssetId.toString()]
            dispatch({ type: 'SET_ERROR_ASSETS', payload: errorAssets })
        }
        setError(null)
    }
    const buyContainerClassName = cn(
        'relative grid grid-cols-2 space-x-2 font-bold text-black dark:!text-white block w-full my-auto md1:mb-2 md1:-mt-2',
        { 'text-center': view !== 'full' },
        { 'text-left': view === 'full' },
    )
    const singleContainerClassName = cn(
        'mt-auto relative grid font-bold text-black dark:!text-white block text-center grid-cols-1 w-full my-auto',
    )
    const sellContainerClassName = cn(
        'mt-auto relative grid grid-cols-2 font-bold text-black dark:!text-white block w-full my-auto',
        { 'text-center': view !== 'full' },
        { 'text-left right-auto': view === 'full' },
    )
    const lbuttonClassName = cn(
        'w-full h-auto md:h-10 xl:h-7 min-w-32 text-base md:text-sm',
        { 'm-auto': view !== 'full' },
        { 'mr-auto': view === 'full' },
    )
    const buttonClassName = cn(
        'font-bold rounded-md text-primary',
        { 'm-auto': view !== 'full' },
        { 'mr-auto': view === 'full' },
    )
    const cancelButtonClassName = cn(buttonClassName, 'm-auto w-24 text-white')
    const usdClassName = cn('my-auto text-xs')

    const detailsButton =
        view === 'full' ? (
            ''
        ) : (
            <MainButton
                fullWidth={true}
                className={'font-bold rounded-md text-primary'}
                varient={'bordered'}
                onClick={onOpen}
                padding={'pr-2'}
            >
                {t('asset.details')}
            </MainButton>
        )

    const sellField = (
        <div className={sellContainerClassName}>
            {detailsButton}
            {[
                'atomicassets',
                'simpleassets',
                'packs.topps',
                'packs.ws',
                'pack.worlds',
            ].includes(standard) ? (
                <MainButton
                    fullWidth={true}
                    className={buttonClassName}
                    onClick={sell}
                    padding={'pl-2'}
                >
                    {t('asset.sell')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const detailsField = (
        <div className={singleContainerClassName}>{detailsButton}</div>
    )

    const cancelField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton className={cancelButtonClassName} onClick={cancel}>
                {t('asset.cancel')}
            </MainButton>
        </div>
    )

    const editField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            {edited ? (
                <CheckIndicator />
            ) : (
                <MainButton varient={'solid'} fullWidth={true} onClick={edit}>
                    {t('asset.edit')}
                </MainButton>
            )}
        </div>
    )

    const buyField =
        page === 'live' ? (
            <div className={singleContainerClassName}>
                <MainButton
                    fullWidth={true}
                    varient={'solid'}
                    onClick={state.skipBuyConfirmation ? directBuy : buy}
                >
                    {t('asset.buy')}
                </MainButton>
            </div>
        ) : (
            <div className={buyContainerClassName}>
                {detailsButton}
                <MainButton
                    fullWidth={true}
                    varient={'solid'}
                    // size="sm"
                    onClick={state.skipBuyConfirmation ? directBuy : buy}
                >
                    {t('asset.buy')}
                </MainButton>
            </div>
        )

    const loginField = (
        <div className={buyContainerClassName}>
            {detailsButton}
            <MainButton
                fullWidth={true}
                varient={'solid'}
                onClick={() => login()}
            >
                {t('navigation.login')}
            </MainButton>
        </div>
    )

    const addField = (
        <div className={singleContainerClassName}>
            <MainButton
                fullWidth={true}
                className={buttonClassName}
                onClick={() => onAdd(asset)}
            >
                {t('trade.add')}
            </MainButton>
        </div>
    )

    let buyable, sellable, checked, editable, cancable

    if (
        offer &&
        seller &&
        (!userName || userName !== seller) &&
        !bought &&
        market
    ) {
        buyable = true
    } else if (
        userName &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        !offer &&
        (!sold || bought) &&
        !edited &&
        !canceled &&
        !forSale &&
        (!page || page === 'inventory' || page === 'live')
    ) {
        sellable = true
    } else if (
        userName &&
        (userName === seller || sold) &&
        offer &&
        !canceled &&
        ['atomicmarket'].includes(newOwner ? newOwner : market ? market : owner)
    ) {
        editable = true
    } else if (
        userName &&
        (((userName === seller || sold) && market !== 'waxbuyoffers') ||
            (userName === buyer && market === 'waxbuyoffers' && !sold)) &&
        offer &&
        !canceled &&
        [
            'nft.hive',
            'waxplorercom',
            'simplemarket',
            'waxinventory',
            'market.myth',
            'alcornftswap',
            'waxarena3dk1',
            'waxbuyoffers',
            'atomicmarket',
        ].includes(newOwner ? newOwner : market ? market : owner)
    ) {
        cancable = true
    }

    return (
        <div className={cn('relative mt-4', className)}>
            <div
                className={cn(
                    'relative py-0 px-0 2xl:px-1 w-full text-base mb-6 text-zinc-900 dark:text-white',
                    'md:text-xs lg:text-base leading-5 flex-wrap text-center h-[28px] sm:h-[44px]',
                    { 'text-center': view !== 'full' },
                    { 'text-left': view === 'full' },
                )}
            >
                <div
                    className={cn(
                        'flex h-[20px] sm:h-2/3',
                        { 'justify-start mx-auto': view !== 'full' },
                        { 'justify-start mr-auto': view === 'full' },
                    )}
                >
                    {usd_price && !offer && (
                        <div className={usdClassName}>
                            <b>{` / ${formatUSD(usd_price)}`}</b>
                        </div>
                    )}
                    {offer ? (
                        <div
                            className={
                                'text-xs md:text-base lg:text-base xl:text-xs text-right my-auto font-bold'
                            }
                        >
                            <span className="hidden sm:block">
                                <b>{formatWAX(offer)}</b>
                            </span>
                            <span className="block sm:hidden">
                                <b>{formatWAXS(offer)}</b>
                            </span>
                        </div>
                    ) : (
                        ''
                    )}
                    {usd_offer && offer ? (
                        <div
                            className={
                                'text-sm ml-1 text-left my-auto md:text-sm xl:text-xs '
                            }
                        >
                            <b>{` (${formatUSD(usd_offer)})`}</b>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {view !== 'full' &&
                (lowest || attributesFloor) &&
                assetType !== 'pfpranking' ? (
                    <div
                        className={cn(
                            'flex text-sm md:text-1xs opacity-100 md:opacity-80 h-[16px] hover:opacity-100',
                            'cursor-pointer my-auto leading-1 ',
                            { 'justify-start': view !== 'full' },
                            { 'justify-start': view === 'full' },
                        )}
                        onClick={() =>
                            openSearchPopup(
                                'sales',
                                assets ? assets[currentAsset] : asset,
                                dispatch,
                            )
                        }
                    >
                        {assetType === 'pack-content' ? (
                            <div className={'w-full font-bold text-base'}>
                                <b>{`${t('drops.remaining')}: ${
                                    asset['numRemaining']
                                }`}</b>
                            </div>
                        ) : (
                            ''
                        )}
                        <p className="text-[11px]">
                            {' '}
                            <b>{`${t('asset.floor')}: ${formatWAX(
                                offer && market !== 'waxbuyoffers'
                                    ? Math.min(
                                          attributesFloor
                                              ? attributesFloor
                                              : lowest,
                                          offer,
                                      )
                                    : attributesFloor
                                    ? attributesFloor
                                    : lowest,
                            )}`}</b>
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </div>

            <div
                className={'flex h-auto my-auto mx-auto w-full justify-center'}
            >
                {!isLoading && !blacklisted && onAdd ? addField : ''}
                {isLoading ? (
                    <LoadingIndicator
                        className={buttonClassName}
                        margin={'m-0'}
                    />
                ) : !blacklisted && !onAdd && buyable ? (
                    userName ? (
                        buyField
                    ) : (
                        loginField
                    )
                ) : !blacklisted && !onAdd && sellable ? (
                    sellField
                ) : !blacklisted && !onAdd && cancable && !editable ? (
                    cancelField
                ) : !blacklisted && !onAdd && editable ? (
                    editField
                ) : !sellable &&
                  !showOwner &&
                  !editable &&
                  !cancable &&
                  !buyable &&
                  !checked &&
                  !sold &&
                  !canceled ? (
                    detailsField
                ) : (
                    ''
                )}
                {!isLoading && !onAdd && (error || popError) ? (
                    <ErrorNote
                        onClick={disMissError}
                        error={error ? error : popError.message}
                        className={'-top-8 rounded-2xl'}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default MarketButtons
