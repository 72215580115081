import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import {
    CircularProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { get } from '../helpers/Api'
import { createAuthors } from '../helpers/FormatLinks'
import { VerifiedSelector2 } from '../verifiedselector/VerifiedSelector2'
import { Input } from '@nextui-org/react'
const CollectionSelector = ({
    market,
    owner,
    searchType,
    searchPFPs,
    onCheckVerified,
    verificationOptions = true,
    searchAuthor,
    searchVerified,
    selectAuthor,
    searchSearch,
}) => {
    const [authors, setAuthors] = useState(null)
    const [isLoadingCollections, setIsLoadingCollections] = useState(false)
    const [authorSearch, setAuthorSearch] = useState('')

    const [searchTimer, setSearchTimer] = useState(null)

    const [authorDropDownOptions, setAuthorDropDownOptions] = useState(
        authors ? createAuthors(authors, '', searchAuthor) : [],
    )

    const onSearchAuthor = async (e) => {
        if (searchTimer) clearTimeout(searchTimer)
        setSearchTimer(
            setTimeout(() => {
                setAuthorSearch(e.target.value)
            }, 500),
        )
    }

    const onSelectAuthor = (e) => {
        if (selectAuthor)
            selectAuthor(e && e.value !== searchAuthor ? e.value : '')
    }

    const createAuthorDropDownOptions = (authors) => {
        setAuthorDropDownOptions(createAuthors(authors, '', searchAuthor))
        setAuthors(authors)
        setIsLoadingCollections(false)
    }

    const fetchAuthors = async (
        verified,
        term = '',
        searchType = '',
        market = '',
        owner = '',
    ) => {
        if ((!term || term.length > 2) && !isLoadingCollections) {
            setIsLoadingCollections(true)
            await get(
                `authors/${verified}?term=${term ? term : ''}&type=${
                    searchType ? searchType : ''
                }&market=${market ? market : ''}&owner=${
                    owner ? owner : ''
                }&pfps_only=${searchPFPs ? searchPFPs : ''}`,
            ).then((result) => createAuthorDropDownOptions(result))
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined')
            fetchAuthors(
                searchVerified,
                authorSearch,
                searchType,
                market,
                owner,
            )
    }, [searchVerified, authorSearch, market, searchType, owner])

    const getAuthorOption = (options, author) => {
        return options.map((item) => item.value).indexOf(author)
    }

    const defaultOptions = []

    const dropDownOptions = authorDropDownOptions

    if (searchAuthor && searchAuthor !== '*') {
        searchAuthor.split(',').map((author) => {
            if (
                !defaultOptions.includes(
                    dropDownOptions[getAuthorOption(dropDownOptions, author)],
                ) &&
                !(searchAuthor.split(',').length > 1 && author === '*')
            )
                defaultOptions.push(
                    dropDownOptions[getAuthorOption(dropDownOptions, author)],
                )
        })
    }

    return (
        <div className="py-1 ">
            {!searchAuthor || searchAuthor === '*' ? (
                <div className="mb-1">
                    <Input
                        fullWidth
                        size="md"
                        variant="faded"
                        classNames={{
                            label: 'text-black font-semibold dark:text-white',
                            input: 'text-black font-semibold dark:text-white',
                        }}
                        label="Filter Collections..."
                        onChange={onSearchAuthor}
                        endContent={
                            <SearchIcon className="mb-1 pointer-events-none text-zinc-400 text-md" />
                        }
                    />
                </div>
            ) : (
                ''
            )}

            <div
                className={cn(
                    'overflow-y-auto InsideScroll w-full bg-gray-100 rounded-lg border-2 border-gray-200 dark:border-zinc-800 dark:bg-zinc-900',
                    {
                        'h-40': !searchAuthor || searchAuthor === '*',
                    },
                )}
            >
                {isLoadingCollections ? (
                    <div className="grid h-full place-content-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <List dense>
                        {dropDownOptions.map((option) =>
                            !searchAuthor ||
                            searchAuthor === '*' ||
                            option.value === searchAuthor ? (
                                <>
                                    <ListItemButton
                                        divider
                                        onClick={() => onSelectAuthor(option)}
                                        selected={option.value === searchAuthor}
                                    >
                                        {option.value === searchAuthor && (
                                            <ListItemIcon
                                                style={{
                                                    marginRight: '7px',
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </ListItemIcon>
                                        )}
                                        <ListItemText
                                            primary={option.title}
                                            className="font-semibold text-black dark:text-white"
                                        />
                                        {option.image && (
                                            <div className="flex w-8 h-8 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-8 xl:h-8 rounded-2xl">
                                                <img
                                                    className="m-auto rounded-md"
                                                    src={option.image}
                                                    alt={option.title}
                                                />
                                            </div>
                                        )}
                                    </ListItemButton>
                                </>
                            ) : (
                                ''
                            ),
                        )}
                    </List>
                )}
            </div>
            {(!searchAuthor || searchAuthor === '*') && verificationOptions ? (
                <div className="flex justify-center mt-2">
                    <VerifiedSelector2
                        searchVerified={searchVerified}
                        setSearchVerified={onCheckVerified}
                        searchSearch={searchSearch}
                        small={true}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default CollectionSelector
