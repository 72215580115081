// import { Chip } from '@mui/material'
import { Chip } from '@nextui-org/react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import qs from 'qs'
import React from 'react'

export const VerifiedSelector2 = ({
    searchVerified,
    setSearchVerified,
    searchSearch,
    small = false,
}) => {
    const verifiedOptions = [
        { label: 'Verified', value: 'verified' },
        { label: 'All', value: 'all' },
        { label: 'Unverified', value: 'unverified' },
    ]

    if (
        ['assets', 'inventory'].includes(searchSearch) ||
        searchSearch === 'collections'
    )
        verifiedOptions.push({ label: 'Blacklisted', value: 'blacklisted' })

    let values = qs.parse(
        window.location.search.substring(1, window.location.search.length),
    )

    let defaultVerifiedOption = verifiedOptions[0]
    for (let i = 0; i < verifiedOptions.length; ++i) {
        if (verifiedOptions[i].value === searchVerified)
            defaultVerifiedOption = verifiedOptions[i]
    }

    const router = useRouter()

    const setQueryStringWithoutPageReloadPush = (qsValue) => {
        const newPath = window.location.pathname + '?' + qsValue

        router.push(newPath, undefined, { shallow: true })
    }

    const onChangeVerified = (value) => {
        setSearchVerified(value)
    }

    const ToggleButton = ({ label, value }) => {
        return (
            <div className="mx-[2px] font-light">
                <Chip
                    size={small ? 'sm' : 'md'}
                    radius="sm"
                    variant={searchVerified === value ? 'solid' : 'bordered'}
                    /*  color={
                        searchVerified === value
                            ? value === 'blacklisted'
                                ? 'danger'
                                : 'primary'
                            : 'default'
                    } */
                    color="warning"
                    className="cursor-pointer "
                    classNames={cn({
                        'text-black': searchVerified === value,
                        'text-warning ': searchVerified !== value,
                    })}
                    onClick={() => onChangeVerified(value)}
                >
                    {label}
                </Chip>
            </div>
        )
    }

    return (
        <div className="flex flex-wrap justify-center">
            {verifiedOptions.map((option) => (
                <ToggleButton label={option.label} value={option.value} />
            ))}
        </div>
    )
}
