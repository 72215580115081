import React, { useContext } from 'react'
import { CollectionHeaderPopups } from '../popups/CollectionHeaderPopups'
import { PopupImage } from '../popups/PopupImage'
import { Context } from '../waxplorer/Waxplorer'

export const MarketAssetPreviewImage = ({ asset, currentAsset, title }) => {
    const [, dispatch] = useContext(Context)

    const { assets, bundle } = asset
    let { name, image } = assets ? assets[currentAsset] : asset

    const toggleImage = () => {
        dispatch({ type: 'FULL_IMAGE', payload: image })
    }

    return (
        <div className="">
            <div className="text-lg 2xl:text-2xl  text-center my-3 md:my-10 px-4">
                <p>{title ? title : name}</p>
            </div>
            {image && (
                <div className="md:my-5 cursor-pointer px-4 ">
                    <PopupImage image={image} toggleImage={toggleImage} />
                </div>
            )}
            {asset && (
                <div className="mt-7 md:my-10">
                    <CollectionHeaderPopups asset={asset} />
                </div>
            )}
        </div>
    )
}
