import cn from 'classnames'
import React from 'react'
import { useSharedState } from '../waxplorer/Waxplorer'
import MarketAssetPreview from '../assetpreview/MarketAssetPreview'
import LoadingIndicator from '../loadingindicator'

export const MarketList = (props) => {
    const assets = props['assets']
    const listType = props['listType']
    const search = props['search']
    const clickAsset = props['clickAsset']
    const selectedAsset = props['selectedAsset']
    const onAdd = props['onAdd']
    const id = props['id']
    const selectedAssets = props['selectedAssets']
    const bundleView = props['bundleView']
    const loadMoreButton = props['loadMoreButton']
    const isLoading = props['isLoading']
    const limit = props['limit']
    const page = props['page']
    const selectable = props['selectable']
    const searchOwner = props['searchOwner']
    const [state, dispatch] = useSharedState()

    const localSelectedAssets = state.selectedAssets

    const handleCheck = (e, asset) => {
        if (e.target.checked) {
            localSelectedAssets.push(asset)
            dispatch({
                type: 'SET_SELECTED_ASSETS',
                payload: localSelectedAssets,
            })
        } else {
            const realNewSelectedAssets = []
            localSelectedAssets
                .filter(
                    (a) =>
                        (a.assets ? a.assets[0] : a).assetId !==
                        (asset.assets ? asset.assets[0] : asset).assetId,
                )
                .map((item) => realNewSelectedAssets.push(item))
            dispatch({
                type: 'SET_SELECTED_ASSETS',
                payload: realNewSelectedAssets,
            })
        }
    }

    return (
        <div className={'w-full h-auto mb-4 mt-4'}>
            <div
                className={cn(
                    {
                        'grid sm:gap-4 gap-2 grid-cols-1 xsse:grid-cols-2 sm1:grid-cols-3 md:grid-cols-3 md1:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-7 text-center sm:mx-4 mx-2':
                            search !== 'tradable',
                    },

                    {
                        'grid grid-cols-1 md:grid-cols-3 gap-x-5':
                            search === 'tradable',
                    },
                )}
                id={id ? id : ''}
            >
                {assets &&
                    assets
                        .filter(
                            (asset) =>
                                !selectedAssets ||
                                !selectedAssets.includes(
                                    asset.assetId.toString(),
                                ),
                        )
                        .map((asset, index) => (
                            <MarketAssetPreview
                                bundleView={bundleView}
                                index={index}
                                onAdd={onAdd}
                                assetType={listType}
                                key={`${
                                    asset.assetId || asset.aAssetId
                                }_${index}`}
                                asset={asset}
                                page={page}
                                showOwner={props['showOwners']}
                                selectable={selectable}
                                searchOwner={searchOwner}
                                clickAsset={clickAsset}
                                selectedAsset={selectedAsset}
                                enableLiveAutoBuy={state.enableLiveAutoBuy}
                                selectedAssets={state.selectedAssets}
                                transferredAssets={state.transferredAssets}
                                errorAssets={state.errorAssets}
                                isSelected={
                                    state.selectedAssets
                                        .map(
                                            (a) =>
                                                (a.assets?.length > 0
                                                    ? a.assets[0]
                                                    : a
                                                ).assetId,
                                        )
                                        .includes(
                                            (asset.assets?.length > 0
                                                ? asset.assets[0]
                                                : asset
                                            ).assetId,
                                        )
                                        ? (asset.assets?.length > 0
                                              ? asset.assets[0]
                                              : asset
                                          ).assetId
                                        : null
                                }
                                handleCheck={handleCheck}
                            />
                        ))}
            </div>
            <div className={'w-full flex justify-center'}>
                {isLoading ? <LoadingIndicator /> : null}
                {!isLoading &&
                assets &&
                assets.length > 0 &&
                assets.length % limit === 0 &&
                loadMoreButton
                    ? loadMoreButton
                    : ''}
            </div>
        </div>
    )
}
